import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>
                To learn more about our product offering, email<br/>
                <a href="mailto:CustomerService@FinQuantumInc.com">CustomerService@FinQuantumInc.com</a><br/>
                We'd love to partner with you to help improve your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Fin Quantum Inc.
          </p>
        </div>
      </div>
    </div>
  );
};
